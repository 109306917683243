<template>
  <v-dialog v-model="isOpen" persistent width="auto">
    <v-card :loading="isLoading" max-width="820px">
      <v-card-title class="text-h5">
        {{ welcomePopupTitle }}
      </v-card-title>
      <transition name="fade">
        <v-card-text v-if="step === 1" key="`welcome-step-1`">
          <v-row>
            <v-col cols="12">
              <p>Welcome to NH Hub, your all-in-one property management solution! Say goodbye to time-consuming tasks and welcome a streamlined experience. With NH Hub, you can effortlessly list and manage properties using the power of AI.</p>
              <p class="mt-5">
                Watch our video tutorial to discover the full potential of NH Hub. Let's simplify your property listing and management journey together!
              </p>
              <div class="d-flex justify-center mt-8">
                <CommonYoutube video-url="https://www.youtube.com/watch?v=7h1pmfy7BlI" width="680px" height="376px" hide-overlay hide-player-controls />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="step === 2" key="`welcome-step-2`">
          <ElementsFileUploader v-model="userAvatar" class="ma-auto" title="Select or upload your avatar" :upload-path="`/users/${authUserStore.user.did}/avatars`" :pre-set-files="defaultAvatars" @fileUploaded="saveAvatarAgainstUser" @removeClicked="removeAvatarImage">
            <v-avatar rounded="1" size="120">
              <UserAvatar :avatar-url="userAvatar?.url" />
            </v-avatar>
          </ElementsFileUploader>
        </v-card-text>
        <v-card-text v-else-if="step === 3" key="`welcome-step-3`">
          <p v-if="authUserStore.user.roleId === 3">
            You're now ready to set up your company profile and dive into the full potential of NH Hub.
          </p>
          <p class="mt-5">
            As you navigate through the platform, keep an eye out for the <CommonBotAvatar size="22" /> ai button. Whenever you come across it, simply click to unleash the power of AI, effortlessly filling in content or do more with its assistance.
          </p>
          <p class="mt-5 text-justify">
            <strong class="mr-2">Responsible AI Use Policy Summery!</strong>Please note that NH Hub AI generates draft content to facilitate your workflow. However, it is your sole responsibility to thoroughly review and verify all content before publishing. The system does not automatically publish any content authored by the AI. By clicking the publish button, you confirm that you have reviewed and ensured the accuracy, correctness, and suitability of the content. We strongly advise exercising due diligence in assessing and validating the content's quality before making it publicly available.
          </p>
          <v-checkbox v-model="agreedToTerms" class="mt-5">
            <template #label>
              I confirm that I have read and agree to the <a class="mr-1 ml-1" href="https://www.nhhub.io/terms-of-use" target="_blank">Terms of Use</a> and the Responsible AI Use Policy.
            </template>
          </v-checkbox>
        </v-card-text>
      </transition>
      <v-card-actions>
        <v-btn v-if="step > 1" color="grey" @click="goBack()">
          Back
        </v-btn>
        <v-spacer />
        <v-btn color="primary" :loading="isLoading" :disabled="(step === 2 && !userAvatar) || (step === 3 && !agreedToTerms)" @click="goNext()">
          {{ step === 3 ? finalStepButtonText : 'Next' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang='ts' setup>
import {ComputedRef, Ref} from 'vue';
import {FileObject} from '~/@types/common';
import {useAuthUserStore} from '~/store/authUser';
import UserApi from '~/api/userApi';
import {useCompanyStore} from '~/store/comapny';
import CompanyApi from '~/api/companyApi';
import AuthUserApi from '~/api/authUserApi';


// EMIT Definitions
//----------------------------------------------------------------------------------------------------------------------
const emit = defineEmits<{
  (event: 'update:modelValue', payload: boolean): void;
}>();


// PROPS Definitions
//----------------------------------------------------------------------------------------------------------------------
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});

// DATA Definitions
//----------------------------------------------------------------------------------------------------------------------
const isOpen: Ref<boolean> = ref(false);
const isLoading: Ref<boolean> = ref(false);
const step: Ref<number> = ref(1);
const authUserStore = useAuthUserStore();
const companyStore = useCompanyStore();
const agreedToTerms: Ref<boolean> = ref(false);
const userAvatar: Ref<FileObject | null> = ref(null);
const router = useRouter();
const defaultAvatars: Ref<FileObject[]> = ref([
  {url: '/images/avatars/avatar-1.webp', removable: false, caption: null},
  {url: '/images/avatars/avatar-2.webp', removable: false, caption: null},
  {url: '/images/avatars/avatar-3.webp', removable: false, caption: null},
  {url: '/images/avatars/avatar-4.webp', removable: false, caption: null},
  {url: '/images/avatars/avatar-5.webp', removable: false, caption: null},
  {url: '/images/avatars/avatar-6.webp', removable: false, caption: null},
  {url: '/images/avatars/avatar-7.webp', removable: false, caption: null},
  {url: '/images/avatars/avatar-8.webp', removable: false, caption: null},
]);

/**
 * @_MOUNTED - Hook
 */
onMounted(() => {
  if (props.modelValue !== isOpen.value) {
    isOpen.value = props.modelValue;
  }
});

/**
 * @_WATCHER modelValue
 */
watch(() => props.modelValue, () => {
  if (props.modelValue !== isOpen.value) {
    isOpen.value = props.modelValue;
  }
});

/**
 * @_WATCHER isOpen
 */
watch(() => isOpen.value, () => {
  if (props.modelValue !== isOpen.value) {
    emit('update:modelValue', isOpen.value);
  }
});

/**
 * @_WATCH userAvatar changes
 */
watch(() => userAvatar.value, () => {
  if (userAvatar.value && userAvatar.value.url) {
    setNewAvatar((userAvatar.value.url as string));
  }
});

/**
 * Go to next step
 */
function goNext() {
  if (step.value === 3) {
    runCompletionStep();
  } else {
    step.value++;
  }
}

/**
 * Go to the previous step
 */
function goBack () {
  if (step.value > 1) {
    step.value--;
  }
}

/**
 * Run completion step and mark initialSetupDone to true
 */
async function runCompletionStep () {
  // When a company setup complete
  if (companyStore.myCompany && companyStore.myCompany.meta && !companyStore.myCompany.meta.initialSetupDone) {
    isLoading.value = true;
    await CompanyApi.update([companyStore.myCompany.did], {
      meta: {...companyStore.myCompany.meta, ...{initialSetupDone: true}},
    });
    // Update the user
    await AuthUserApi.get();
    isOpen.value = false;
    nextTick(() => {
      router.push(`/companies/${companyStore.myCompany?.did}`);
    });
    isLoading.value = false;
  }

  // When a user setup complete
  if (authUserStore.user && authUserStore.user.metaData && !authUserStore.user.metaData.initialSetupDone) {
    isLoading.value = true;
    await UserApi.update([authUserStore.user.did], {metaData: {...authUserStore.user.metaData, ...{initialSetupDone: true}}});
    // Update the user
    await AuthUserApi.get();
    isLoading.value = false;
    isOpen.value = false;
  }
}

/**
 * Get final step button text
 */
const finalStepButtonText: ComputedRef<string> = computed(() => {
  if (authUserStore.user && authUserStore.user.roleId > 3) {
    return 'Done';
  } else {
    return 'Go To Company Profile';
  }
});

/**
 * Set user avatar
 *
 * @param avatarUrl
 */
async function setNewAvatar (avatarUrl: string) {
  if (avatarUrl && authUserStore.user) {
    try {
      isLoading.value = true;
      await UserApi.update([authUserStore.user.did], {avatarUrl});
      isLoading.value = false;
    } catch (error) {
      //
    }
  }
}

/**
 * Set user avatar
 *
 * @param fileObject
 */
async function saveAvatarAgainstUser (fileObject: FileObject) {
  if (fileObject && fileObject.url && authUserStore.user) {
    let metaData = authUserStore.user.metaData;
    if (metaData && metaData.avatarUrls) {
      metaData.avatarUrls.push((fileObject.url as string));
    } else if (metaData) {
      metaData.avatarUrls = [(fileObject.url as string)];
    } else {
      metaData = {avatarUrls: [(fileObject.url as string)]};
    }

    await UserApi.update([authUserStore.user.did], {metaData});
  }
}

/**
 * Set user avatar
 *
 * @param fileObject
 */
async function removeAvatarImage (fileObject: FileObject) {
  if (fileObject && fileObject.url && authUserStore.user) {
    let avatarUrl = authUserStore.user.avatarUrl;
    // Remove the current selected avatar image if this deleting one is the current selected
    if (fileObject.url === avatarUrl) {
      avatarUrl = null;
    }
    let metaData = authUserStore.user.metaData;
    if (metaData && metaData.avatarUrls) {
      const avatarUrlIndex = metaData.avatarUrls.findIndex((url) => url === fileObject.url);
      metaData.avatarUrls.splice(avatarUrlIndex, 1);
    }

    const removingIndex = defaultAvatars.value.findIndex((avatar) => avatar.url === fileObject.url);
    defaultAvatars.value.splice(removingIndex, 1);

    await UserApi.update([authUserStore.user.did], {metaData, avatarUrl});
  }
}

/**
 * Set the popup title
 */
const welcomePopupTitle: ComputedRef<string> = computed(() => {
  switch (step.value) {
    case 1:
      return 'Welcome To NH Hub';
    case 2:
      return 'Let\'s set your profile picture';
    case 3:
      return 'Congratulations on completing the initial setup!';
    default:
      return 'Welcome To NH Hub';
  }
});
</script>
