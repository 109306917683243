<template>
  <v-breadcrumbs :items="breadCrumbs" />
</template>

<script lang="ts" setup>
import {ComputedRef} from 'vue';
import {Breadcrumb} from '~/@types/common';
import {useGlobalStore} from '~/store/global';

// DATA Definitions
//----------------------------------------------------------------------------------------------------------------------
const globalStore = useGlobalStore();

/**
 * Set breadcrumbs
 */
const breadCrumbs: ComputedRef<Breadcrumb[]> = computed(() => {
  return [
    ...[
      {
        title: 'Dashboard',
        disabled: false,
        to: '/',
      },
    ],
    ...globalStore.breadcrumbs,
  ];
});
</script>

<style lang="scss">
.v-breadcrumbs {
  padding: 0;
  margin: -8px 0 5px -4px;

  a, li {
    @include light-typography-caption;
    color: $light-text-secondary;
  }
}
</style>
