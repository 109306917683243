<template>
  <div id="__clou-default-layout" class="clou-default-layout">
    <v-container fluid>
      <v-layout>
        <!-- Test mode bar -->
        <div v-if="config.public.appEnv !== 'production'" class="__test-mode-wrapper">
          <div class="__test-mode-wrapper__content">
            Test Mode - {{ config.public.appEnv }}
          </div>
        </div>

        <!-- Default Navigation Bar -->
        <LayoutsDefaultNavBar />

        <!-- Default App Bar -->
        <LayoutsDefaultAppBar />

        <v-main style="min-height: 300px;">
          <!-- Alert component -->
          <CommonAlert />

          <!-- Breadcrumbs -->
          <LayoutsDefaultBreadcrumb />

          <!-- Page Loading Bar -->
          <NuxtLoadingIndicator color="#787eff" />

          <!-- Bot -->
          <CommonBot />

          <!-- Company Welcome -->
          <CompanyWelcome v-model="isCompanyWelcomOpen" />

          <!-- Nuxt Page -->
          <NuxtPage />
        </v-main>

        <!-- Unsaved changes dialog -->
        <CommonUnsavedChangesWarning />

        <!-- Footer -->
        <v-footer app height="24">
          <div class="footer-author">
            <small>
              New Homes © 2022 | Design & developed by <a href="https://www.clou.co.nz" target="_blank">Clou Digital</a>
            </small>
          </div>
          <v-spacer />
          <div class="footer-links">
            <small>
              <a href="https://newhomes.co.nz/nh-hub-terms-of-use" target="_blank">Terms of use</a>
            </small>
          </div>
        </v-footer>
      </v-layout>
    </v-container>
  </div>
</template>

<script lang="ts" setup>
import {Ref} from 'vue';
import {useCompanyStore} from '~/store/comapny';
import access from '~/composables/access';
import {useAuthUserStore} from '~/store/authUser';
import {useRuntimeConfig} from '#app';

const {$initSocket} = useNuxtApp();
const config = useRuntimeConfig();
const isCompanyWelcomOpen: Ref<boolean> = ref(false);
const companyStore = useCompanyStore();
const authUserStore = useAuthUserStore();


/**
 * Default Layout MOUNTED!
 */
onMounted(() => {
  // Initiate web socket
  $initSocket();

  setTimeout(() => {
    initCompanyWelcome();
  }, 1000);
});

function initCompanyWelcome () {
  if (
    access.isCompanyAdmin() && companyStore.myCompany && companyStore.myCompany.meta && companyStore.myCompany.meta.initialSetupDone === false
    || (authUserStore.user && authUserStore.user.metaData && !authUserStore.user.metaData.initialSetupDone)) {
    isCompanyWelcomOpen.value = true;
  }
}
</script>

<style lang="scss">
.__test-mode-wrapper {
  top: 0;
  position: fixed;
  width: 100%;
  left: 0;
  height: 5px;
  background: #fdb529;
  z-index: 9999;
  box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.5);

  &__content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
    padding: 2px 12px 1px;
    text-transform: capitalize;
    text-align: center;
    background: #fdb529;
    z-index: 99999;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.5);
    font-size: 14px;
  }
}
</style>
