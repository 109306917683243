<template>
  <div class="common-alert">
    <TransitionGroup name="alert">
      <v-alert v-for="(alert, index) in globalStore.alerts" :key="`alert-${index}`" density="compact" :type="alert.type || 'success'" :closable="alert.dismissible" variant="elevated" elevation="1">
        {{ alert.message }}
        <div v-if="alert.action && alert.action.label && alert.action.to" class="d-flex justify-end mt-2">
          <v-btn variant="outlined" size="x-small" :to="alert.action.to">
            {{ alert.action?.label }}
          </v-btn>
        </div>
      </v-alert>
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
import {useGlobalStore} from '~/store/global';

// DATA Definitions
//--------------------------------------------
const globalStore = useGlobalStore();
</script>

<style lang="scss">
.common-alert {
  position: fixed;
  z-index: 3000;
  right: 0;
  top: 68px;
  max-width: 420px;
  width: 100%;

  .v-alert {
    margin-bottom: 5px;

    .v-alert__close {
      margin-inline-start: 12px;
    }
  }

  .alert-enter-active,
  .alert-leave-active {
    transition: all 0.5s ease;
  }
  .alert-enter-from,
  .alert-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }
}
</style>
