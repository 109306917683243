<template>
  <!-- Unsaved changes dialog -->
  <v-dialog v-model="showUnsavedChangesDialog" width="360">
    <v-card>
      <v-card-text class="pt-5">
        This page contains unsaved changes. Do you still wish to leave?
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn color="red" text @click="proceedToNextPage">
          Yes
        </v-btn>
        <v-spacer />
        <v-btn color="green" text @click="showUnsavedChangesDialog = false">
          No
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import {Ref} from 'vue';
import {useGlobalStore} from '~/store/global';
import {useRouter} from '#app';

// DATA Definitions
//----------------------------------------------------------------------------------------------------------------------
const showUnsavedChangesDialog: Ref<boolean> = ref(false);
const globalStore = useGlobalStore();
const router = useRouter();
const nextObject: Ref<any> = ref(null);

/**
 * @_MOUNTED Hook
 */
onMounted(() => {
  preventUnsavedChanges();
});

/**
 * Prevent navigating where there are any unsaved changes
 *
 * @private
 */
function preventUnsavedChanges () {
  // Browser level router change (refresh or navigating to a different site)
  window.addEventListener('beforeunload', (e) => {
    if (globalStore.hasUnsavedChanges) {
      const confirmationMessage = 'This page contains unsaved changes. Do you still wish to leave?';
      (e || window.event).returnValue = confirmationMessage; // Gecko + IE
      return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
    }
  });

  // Application level route changes
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  router.beforeEach((to: any, from: any, next: any) => {
    if (globalStore.hasUnsavedChanges) {
      nextObject.value = next;
      showUnsavedChangesDialog.value = true;
    } else {
      globalStore.setHasUnsavedChanges(false);
      next();
    }
  });
}

/**
 * Proceed to the next page
 */
function proceedToNextPage () {
  globalStore.setHasUnsavedChanges(false);
  if (nextObject.value) {
    nextObject.value();
  }
  showUnsavedChangesDialog.value = false;
}
</script>
