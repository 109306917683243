<template>
  <div class="common-youtube" :style="`width: ${width}; height: ${height};`">
    <div id="player" />
    <div v-if="hidePlayerControls === false" class="__player-controls">
      <img :src="playStatus === 'play' ? pauseButton : playButton" :alt="`${playStatus === 'play' ? 'Pause' : 'Play'} button`" @click="togglePlayPause">
    </div>
    <div v-if="hideOverlay === false" class="__player-overlay" />
  </div>
</template>

<script setup lang="ts">
import {Ref} from 'vue';
import playButton from '~/assets/images/icons/play-btn.webp';
import pauseButton from '~/assets/images/icons/pause-btn.webp';

// DATA
let player: any;
const isPlayerReady: Ref<boolean> = ref(false);
const playStatus: Ref<'play' | 'pause' | 'ended' | 'buffering' | 'unstarted' | 'unknow'> = ref('unstarted');

// PROPS
const props = defineProps({
  initIframe: {
    type: Boolean,
    default: true,
  },
  autoPlay: {
    type: Boolean,
    default: true,
  },
  playVideo: {
    type: Boolean,
    default: false,
  },
  videoUrl: {
    type: String,
    default: '',
  },
  width: {
    type: String,
    default: '640px',
  },
  height: {
    type: String,
    default: '320px',
  },
  hidePlayerControls: {
    type: Boolean,
    default: false,
  },
  hideOverlay: {
    type: Boolean,
    default: false,
  },
});

// Emit Definition
const emit = defineEmits<{
  (event: 'videoStopped'): void;
}>();

/**
 * Component MOUNTED!
 */
onMounted(() => {
  isPlayerReady.value = false;
  initIframe();
});

/**
 * When playVideo prop changed
 */
watch(() => props.playVideo, () => {
  if (props.playVideo) {
    playVideo();
  } else {
    pauseVideo();
  }
});

/**
 * When initIframe prop changed
 */
watch(() => props.initIframe, () => {
  if (props.initIframe) {
    initIframe();
  }
});

/**
 * Init YouTube iframe
 */
function initIframe () {
  if (props.initIframe && props.videoUrl) {
    if (window && (window as any).YT) {
      loadVideo();
    } else {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';

      const firstScriptTag = document.getElementsByTagName('script')[0];
      if (firstScriptTag && firstScriptTag.parentNode) {
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }

      setTimeout(() => {
        initIframe();
      }, 500);
    }
  }
}

/**
 * Load YouTube video
 */
function loadVideo() {
  isPlayerReady.value = false;
  player = new (window as any).YT.Player('player', {
    height: '100%',
    width: '100%',
    videoId: getVideoIdFromUrl(),
    playerVars: {
      'playsinline': 1,
      'autoplay': props.autoPlay ? 1 : 0,
      'controls': 1,
      'modestbranding': 1,
      'rel': 0,
      'showinfo': 0,
    },
    events: {
      'onReady': onPlayerReady,
      'onStateChange': onPlayerStateChange,
    },
  });
}

/**
 * Get video id from url
 */
function getVideoIdFromUrl() {
  if (!props.videoUrl) return;
  let videoId = props.videoUrl.split('v=')[1];
  const ampersandPosition = videoId.indexOf('&');
  if(ampersandPosition != -1) {
    videoId = videoId.substring(0, ampersandPosition);
  }
  return videoId;
}

/**
 * When player is ready
 */
function onPlayerReady() {
  isPlayerReady.value = true;
}

/**
 * When player status changed
 */
function onPlayerStateChange() {
  const playerStatus = player.getPlayerState();
  switch (playerStatus) {
    case -1:
      playStatus.value = 'unstarted';
      break;
    case 0:
      playStatus.value = 'ended';
      break;
    case 1:
      playStatus.value = 'play';
      break;
    case 2:
      playStatus.value = 'pause';
      break;
    case 3:
      playStatus.value = 'buffering';
      break;
    default:
      playStatus.value = 'unknow';
      break;
  }
  if (playStatus.value === 'ended') {
    emit('videoStopped');
  }
}

/**
 * Toggle the play pause video
 */
function togglePlayPause () {
  if (playStatus.value === 'play') {
    pauseVideo();
  } else if (playStatus.value === 'pause') {
    playVideo();
  }
}

/**
 * Play video
 */
function playVideo() {
  player.playVideo();
}

/**
 * Pause video
 */
function pauseVideo() {
  player.pauseVideo();
}
</script>

<style lang="scss">
.common-youtube {
  width: 100%;
  height: 100%;
  position: relative;

  .__player-controls {
    position: absolute;
    width: 100%;
    height: 50px;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.5) 75%, rgba(0,0,0,0) 100%);
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    img {
      width: 30px;
      height: auto;
    }
  }

  .__player-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0, 0);
    top: 0;
    left: 0;
    z-index: 1;
  }
}
</style>
