<template>
  <div class="ai-bot-chat-item">
    <div v-if="chatItem.role === 'ai'" class="ai-bot__bot-response">
      <p v-html="typingText" />

      <!-- Display options if available -->
      <div v-if="chatItem.option" class="ai-bot-chat-item__options" :class="{'--visible': typingCompleted}">
        <div class="ai-bot-chat-item__options-bool">
          <v-btn :disabled="optionSelected" variant="text" color="grey" @click="triggerCallback(false)">
            No
          </v-btn>
          <v-btn :disabled="optionSelected" variant="text" color="primary" @click="triggerCallback(true)">
            Yes
          </v-btn>
        </div>
      </div>
    </div>
    <div v-else class="ai-bot__user-response">
      {{ chatItem.message }}
      <v-avatar size="28">
        <UserAvatar avatar-url="useAuth" />
      </v-avatar>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {PropType, Ref} from 'vue';
import {ChatHistory} from '~/@types/ai';

// PROPS Definitions
//----------------------------------------------------------------------------------------------------------------------
const props = defineProps({
  chatItem: {
    type: Object as PropType<ChatHistory>,
    required: true,
  },
});

// DATA Definitions
//----------------------------------------------------------------------------------------------------------------------
const typingText: Ref<string> = ref('');
const typedTextIndex: Ref<number> = ref(0);
const typingCompleted: Ref<boolean> = ref(false);
const optionSelected: Ref<boolean> = ref(false);
const formattedResponseText: Ref<string> = ref('');

/**
 * @_MOUNTED hook
 */
onMounted(() => {
  initData();
});

/**
 * Iniaite data
 */
function initData () {
  if (typedTextIndex.value < props.chatItem.message.length) {
    formattedResponseText.value = props.chatItem.message;
    formattedResponseText.value = formattedResponseText.value.replace(/(?:\r\n|\r|\n)/g, '<br>');
    animateTyping();
  }
}

/**
 * Animate the typing effect
 */
function animateTyping () {
  if (typedTextIndex.value < formattedResponseText.value.length) {
    typingText.value += formattedResponseText.value.charAt(typedTextIndex.value);
    typedTextIndex.value++;
    setTimeout(animateTyping, 20);
  } else {
    typingCompleted.value = true;
  }
}

/**
 * Trigger the callback function
 *
 * @param selectedOption
 */
function triggerCallback (selectedOption: any) {
  if (props.chatItem.option && props.chatItem.option.callbacks) {
    const callback = props.chatItem.option.callbacks.find((callback) => callback.option === selectedOption);
    if (callback) {
      callback.callbackFunction();
    }
  }

  optionSelected.value = true;
}
</script>

<style lang="scss">
.ai-bot-chat-item {
  .ai-bot__bot-response,
  .ai-bot__user-response {
    margin-bottom: 12px;
  }

  .ai-bot-chat-item__options {
    opacity: 0;

    &.--visible {
      opacity: 1;
    }

    transition: opacity 0.4s 0.2s ease-in-out;

    .ai-bot-chat-item__options-bool {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
