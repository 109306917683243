<template>
  <Transition name="bot">
    <div v-if="aiBotStore.isVisible" class="ai-bot" :class="{'--has-variant': !!aiBotStore.variant}">
      <div class="ai-bot-wrapper">
        <v-btn v-if="aiBotStore.variant" class="ai-bot-close" variant="text" @click="hideBot">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
        <CommonBotAvatar :animation="animation" />
        <v-card v-if="aiBotStore.variant" flat color="transparent">
          <v-card-text class="ai-bot__content-wrapper">
            <TransitionGroup name="list">
              <div v-for="(chatItem, key) in aiBotStore.chatHistory" :key="`chat-${key}`" class="ai-bot__content-item">
                <CommonBotChatItem :chat-item="chatItem" />
              </div>
            </TransitionGroup>
          </v-card-text>
          <v-card-actions v-if="['chat', 'input-callback'].includes(aiBotStore.variant)">
            <v-textarea v-model="message" auto-grow rows="1" variant="underlined" append-inner-icon="mdi-send" @click:appendInner="sendMessage" @keydown.prevent.enter="sendMessage" />
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import {Ref} from 'vue';
import AiApi from '~/api/aiApi';
import {useAIBotStore} from '~/store/aiBot';
import {AIAnimation} from '~/@types/ai';

// DATA Definitions
//----------------------------------------------------------------------------------------------------------------------
const aiBotStore = useAIBotStore();
const message: Ref<string> = ref('');
const animation: Ref<AIAnimation> = ref(null);

/**
 * Send a chat message
 */
async function sendMessage () {
  if (message.value && message.value.length > 0) {
    const chatMessage = message.value;
    message.value = '';
    aiBotStore.pushMessage({
      role: 'user',
      message: chatMessage,
      created_at: '',
    });

    if (aiBotStore.variant === 'chat') {
      animation.value = 'heart-beat';
      const response = await AiApi.chat(chatMessage);
      if (!response) {
        aiBotStore.pushMessage({
          role: 'ai',
          message: 'error',
          created_at: '',
        });
      } else {
        aiBotStore.pushMessage({
          role: 'ai',
          message: response,
          created_at: '',
        });
      }
      animation.value = null;
    } else if (aiBotStore.variant === 'input-callback' && aiBotStore.callbackFunction) {
      aiBotStore.callbackFunction(chatMessage);
      aiBotStore.setCallbackFunction(null);
    }
  }
}

/**
 * Hide the bot
 */
function hideBot () {
  aiBotStore.setVisibility(false);
}
</script>

<style lang="scss">
.ai-bot {
  position: fixed;
  z-index: 999;
  bottom: 25px;
  left: 0;
  right: 0;
  margin: auto;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(2px);
  width: fit-content;
  height: fit-content;
  border-radius: 15px;

  .ai-bot-wrapper {
    display: flex;
    align-items: center;
    padding: 12px;
    position: relative;
    overflow: hidden;
  }

  .ai-bot-close {
    position: absolute;
    top: -6px;
    right: -20px;
  }

  &.--has-variant {
    border: 2px solid rgba(92, 82, 198, 0.49);
  }

  transition: all 0.2s ease-in-out;

  .v-text-field,
  .v-card-actions,
  .v-card-text {
    padding: 0 !important;
  }

  .v-card {
    padding: 0 12px !important;
  }

  .v-text-field {
    width: 320px;
  }

  .ai-bot__content-wrapper {
    max-height: 450px;
    max-width: 540px;
    overflow: auto;
    margin-left: 5px;

    .ai-bot__user-response {
      background: rgba(102, 108, 255, 0.1);
      border-radius: 12px;
      display: flex;
      justify-content: flex-end;
      padding: 8px;
      margin: 12px 0;
      align-items: center;

      .v-avatar {
        margin-left: 12px;
      }
    }
  }
}
</style>
